exports.onClientEntry = () => {
  if (
    process.env.IN_SITE_MAINTENANCE === "true" &&
    window.location.pathname !== "/maintenance" &&
    !window.location.pathname.includes("/auth")
  ) {
    window.location = "/maintenance";
  }
  const event = new Event("fullyLoaded");
  window.dispatchEvent(event);
  window.hasFullyLoaded = true;
};
